.final {
  height: 100%;
  padding: 20px 22px 40px 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-family: 'IQOS';
  font-style: normal;
  font-weight: 700;
  font-size: 43px;
  line-height: 52px;
  text-align: center;
  color: #34303d;
  margin-bottom: 20px;
}

.subtitle {
  font-family: 'IQOS';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #34303d;
}

.img {
  margin-top: 42px;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-height: 700px) {
  .img {
    margin-top: 20px;
    height: 140px;
  }

  .final {
    padding-bottom: 20px;
  }
}
