.one {
  padding: 10px 20px 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 100%;
}

.title {
  font-family: 'IQOS';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: #34303d;
  margin-top: 14px;
  margin-bottom: 32px;
}

.text {
  font-family: 'IQOS';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #34303d;
  text-align: center;
}

.form {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
}

@media screen and (max-height: 800px) {
  .title {
    font-size: 24px;
    line-height: 26px;
  }
  .one {
    padding-bottom: 20px;
  }
}
