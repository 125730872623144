.answer {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px;
  gap: 16px;
  height: 82px;
  background: #ffffff;
  box-shadow: inset 0px 4px 5px rgba(255, 255, 255, 0.59);
  border-radius: 70px;
  width: 100%;
  border: none;
}

.answer.checked {
  background: linear-gradient(155.54deg, #c4f2f8 60.09%, #d4f7fc 85.49%);
  box-shadow: inset 2px 6px 4px rgba(58, 148, 154, 0.33),
    inset -3px -5px 9px rgba(255, 255, 255, 0.67),
    inset 8px 7px 10px rgba(32, 143, 178, 0.29);
  border: 3px solid #57e7fc;
}
.answer.true {
  background: #ffffff;
  border: 3px solid #0ccabf;
  box-shadow: inset 0px 4px 5px rgba(255, 255, 255, 0.59);
}

.answer.false {
  background: #ffffff;
  border: 3px solid #d43350;
  box-shadow: inset 0px 4px 5px rgba(255, 255, 255, 0.59);
}

.circle {
  min-width: 70px;
  min-height: 70px;
  background: #26d7ee;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle.true {
  background: #0ccabf;
}

.circle.false {
  background: #d43350;
}

.text {
  font-family: 'IQOS';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #34303d;
}

.text.true {
  color: #0ccabf;
}

.text.false {
  color: #d43350;
}
.input {
  display: none;
}

.radioInput {
  display: none;
}

@media screen and (max-height: 700px) {
  .answer {
    height: 63px;
  }

  .circle {
    min-width: 50px;
    min-height: 50px;
  }

  .img {
    height: 25px;
  }
}
