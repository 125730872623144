.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 73px);
  background: rgba(158, 253, 237, 0.757398);
  backdrop-filter: blur(7.5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
}

.container {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 52px 16px 32px;
  gap: 16px;
  background: #ffffff;
  border: 3px solid #2ee1d6;
  border-radius: 36px;
  position: relative;
}

.errorModal {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 52px 16px 32px;
  gap: 16px;
  background: #ffffff;
  border: 3px solid #d43350;
  border-radius: 36px;
  position: relative;
}

.icon {
  position: absolute;
  top: -35px;
  left: calc(50% - 35px);
}

.title {
  font-family: 'IQOS';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #34303d;
}

.text {
  font-family: 'IQOS';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #34303d;
}

.boldText {
  font-family: 'IQOS';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #34303d;
}

@media screen and (max-height: 700px) {
  .overlay {
    padding-bottom: 20px;
  }
}
