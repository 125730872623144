.main {
  height: 100%;
  padding: 0 22px 40px 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-family: 'IQOS';
  font-style: normal;
  font-weight: 700;
  font-size: 43px;
  line-height: 52px;
  text-align: center;
  color: #34303d;
  margin-bottom: 20px;
  position: relative;
}

.subtitle {
  font-family: 'IQOS';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #34303d;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  margin-top: 20%;
  position: relative;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.question2 {
  position: absolute;
  top: -100px;
  right: -20px;
}

.question1 {
  position: absolute;
  top: -150px;
  left: -20px;
}

@media screen and (max-height: 700px) {
  .question1 {
    top: -50px;
    height: 150px;
  }
}

@media screen and (max-height: 600px) {
  .main {
    padding-bottom: 10px;
  }
  .container {
    margin-top: 20px;
  }
}
