.bar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  height: 10px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  gap: 4px;
}

.question {
  width: 100%;
  height: 6px;
  background: #ffffff;
  border-radius: 20px;
}

.activeQuestion {
  width: 100%;
  height: 6px;
  background: #26d7ee;
  border-radius: 20px;
}
